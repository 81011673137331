import { FormControl, Grid, Autocomplete, TextField, MenuItem, ListItem } from "@mui/material";
import React, { memo } from "react";
import { customDisabledOutlineInput, menuItemStyle, paddingX1Props } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IAutoCompleteFormControlProperty } from "@definitions/definitions";
function NewAutoCompleteFormControl({
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  showSelect,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isFreeSolo,
  ...props
}: Readonly<IAutoCompleteFormControlProperty>) {
  const selected = () => {
    return listValues.find(item => typeof item === 'object' ? value === item.value : value === item) ?? '';
  };
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewAutoCompleteFormControl" data-sentry-source-file="NewAutoCompleteFormControl.tsx">
		<FormControl fullWidth margin='normal' {...props} required={isRequired} data-sentry-element="FormControl" data-sentry-source-file="NewAutoCompleteFormControl.tsx">
			<Autocomplete disablePortal freeSolo={isFreeSolo} options={listValues} disabled={isDisabled} value={selected()} isOptionEqualToValue={(option, newValue) => {
        if (typeof option === 'object' && typeof newValue === 'string') {
          return option.value === newValue;
        } else if (typeof option === 'object' && typeof newValue === 'object') {
          return option.value === newValue.value;
        }
        return option === newValue;
      }} getOptionKey={option => {
        if (typeof option === 'object') {
          return option?.value;
        }
        return option;
      }} getOptionLabel={(option: any) => {
        if (typeof option === 'object') {
          return option?.name;
        }
        return option;
      }} sx={{
        maxHeight: 200,
        "@media screen and (max-width: 1366px) and (max-height: 768px)": {
          maxHeight: 136
        },
        ...customDisabledOutlineInput
      }} onChange={(event: any, newValue: any) => {
        let isExisting = false;
        if (typeof newValue === 'object') {
          isExisting = listValues.some(item => typeof item === 'object' ? newValue?.value === item.value : newValue?.value === item);
        } else {
          isExisting = listValues.some(item => typeof item === 'object' ? newValue === item.value : newValue === item);
        }
        if (!isExisting) {
          return '';
        }
        if (typeof newValue === 'object') {
          onChange(newValue?.value ?? '');
          return;
        }
        onChange(newValue);
      }} renderInput={params => <TextField {...params} name={name} value={value} label={showSelect ? `Select ${label}` : label} onChange={e => {
        if (isFreeSolo) {
          onChange(e.target.value);
        }
      }} />} componentsProps={{
        paper: {
          sx: {
            wordBreak: "break-word"
          }
        }
      }} renderOption={(props, option) => {
        const {
          ...optionProps
        } = props;
        return typeof option === 'object' ? <MenuItem {...optionProps} sx={menuItemStyle} key={`${name}-${option.value}`} value={option.value}>
					{option.name}
					</MenuItem> : <MenuItem {...optionProps} sx={menuItemStyle} key={`${name}-${option}`} value={option}>
						{option}
					</MenuItem>;
      }} data-sentry-element="Autocomplete" data-sentry-source-file="NewAutoCompleteFormControl.tsx" />
			{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewAutoCompleteFormControl);