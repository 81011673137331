'use client';

import { IPublicClientApplication, AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { AuthenticatedContext } from "@components/AuthorizationContext";
import { EmbedReportConfiguration } from "@components/PowerBI";
import { GroupItem, StateProfileSchema } from "@lib/definitions";
import { ReportParams } from "pbi-config";
import { getTokenAccess } from "./callApiService";
import { isTokenExpired } from "./jwt";
import { B2C_LOGIN_TYPE, LOGIN_TYPE_STORAGE_NAME } from "clientConst";
import { FormState, IPowerBIEntity, IPropertyType, IStateProfileDetail, ReportEntityType } from "@definitions/index";
import { CheckboxFormControl, DropdownFormControl, DatePickerFormControl, TextFormControl, ToggleFormControl, MultilineTextFormControl } from "@components/FormControl";
import { NewDropdownFormControl, NewTextFormControl, NewMultilineTextFormControl, NewToggleFormControl, NewDatePickerFormControl, EmptyFormControl, NewRadioGroupFormControl, NewMultipleSelectFormControl } from "@components/NewFormControl";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { EmbedReportVisualConfiguration } from "@components/PowerBI/EmbedVisual";
import { IReportVisual, IRlsRole, ISimpleDatasetIDs } from "@definitions/reportDefinitions";
import { DATE_FORMAT, DEFAULT_CURRENCY, DEFAULT_LOCALE, ISO_DATE_FORMAT } from "@constants/common";
import NewAutoCompleteFormControl from "@components/NewFormControl/NewAutoCompleteFormControl";
import moment from "moment";
export const formatCurrency = (amount: any, currency = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  }).format(amount);
};
export const formatShortDateString = (dateString: string) => {
  return dateString ? moment(dateString, ISO_DATE_FORMAT).format(DATE_FORMAT) : null;
};
export const getCurrentDateString = (includeWeekday: boolean = false, locale = DEFAULT_LOCALE) => {
  const currentDate = new Date();
  return formatDateToLocalString(currentDate, includeWeekday, locale);
};
export const getCurrentSaturdayDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const saturday = new Date(today);
  saturday.setDate(today.getDate() + (6 - dayOfWeek));
  return formatDateToLocalString(saturday);
};

// get current Friday
export const getCurrentFridayDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const friday = new Date(today);
  friday.setDate(today.getDate() + (5 - dayOfWeek));
  return formatDateToLocalString(friday);
};
export const formatDateToLocalString = (date: Date, includeWeekday: boolean = false, locale: string = DEFAULT_LOCALE) => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: includeWeekday ? 'long' : undefined
  });
};
const formatDateToLocal = (dateStr: string, locale: string = DEFAULT_LOCALE) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(date);
};
const generatePagination = (currentPage: number, totalPages: number) => {
  // If the total number of pages is 7 or less,
  // display all pages without any ellipsis.
  if (totalPages <= 7) {
    return Array.from({
      length: totalPages
    }, (_, i) => i + 1);
  }

  // If the current page is among the first 3 pages,
  // show the first 3, an ellipsis, and the last 2 pages.
  if (currentPage <= 3) {
    return [1, 2, 3, '...', totalPages - 1, totalPages];
  }

  // If the current page is among the last 3 pages,
  // show the first 2, an ellipsis, and the last 3 pages.
  if (currentPage >= totalPages - 2) {
    return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages];
  }

  // If the current page is somewhere in the middle,
  // show the first page, an ellipsis, the current page and its neighbors,
  // another ellipsis, and the last page.
  return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
};
const checkRole = (authenticatedContext: AuthenticatedContext, authorityRoles: string[], checkAADTenant: boolean = false) => {
  const userGroups = authenticatedContext.userGroups;
  const activeAccount = authenticatedContext.activeAccount;
  if (userGroups && userGroups.length > 0) {
    const hasRole = userGroups.some(userGroup => authorityRoles.some(role => role == userGroup.roleName));
    if (checkAADTenant && activeAccount.tenantId) {
      return hasRole && activeAccount.tenantId === process.env.NEXT_PUBLIC_AAD_TENANT_ID!;
    }
    return hasRole;
  }
  return false;
};
const checkRoleAdmin = (userGroups: GroupItem[], authorityRoles: string[]) => {
  if (userGroups && userGroups.length > 0) {
    return userGroups.some(userGroup => authorityRoles.some(role => role == userGroup.roleName));
  }
  return false;
};
export const convertEmbedReportConfigurationFromPowerBIEntity = (reportEntity: IPowerBIEntity): EmbedReportConfiguration => {
  return {
    id: reportEntity?.entityIDs.filter(id => id.entityType == ReportEntityType.Report)[0]?.entityID,
    workspaceId: reportEntity?.workspaceId,
    paginatedReportId: reportEntity?.entityIDs.filter(id => id.entityType == ReportEntityType.PaginatedReport).map(id => id.entityID).join(";"),
    datasetId: reportEntity?.datasetIDs.map(dataset => dataset.datasetID).join(";"),
    name: reportEntity.name,
    rlsRoles: getRlsRolesFromDatasetIDs(reportEntity?.datasetIDs, reportEntity?.fileName),
    ...ReportParams
  } as EmbedReportConfiguration;
};
export const convertReportVisualToEmbedConfiguration = (reportVisual: IReportVisual): EmbedReportConfiguration => {
  return {
    id: reportVisual.reportId,
    workspaceId: reportVisual.workspaceId,
    name: reportVisual.visualTitle,
    type: "visual",
    cssClassName: "report-visual-embed-container",
    rlsRoles: getRlsRolesFromDatasetIDs(reportVisual?.datasetIDs)
  } as EmbedReportConfiguration;
};
export const getRlsRolesFromDatasetIDs = (datasetIDs: ISimpleDatasetIDs[] | undefined, reportFileName?: string): IRlsRole[] => {
  if (!datasetIDs) {
    return [];
  }
  if (reportFileName) {
    return datasetIDs.filter(dataset => (dataset.name?.startsWith("Model_") || dataset.name === reportFileName) && dataset.rlsRole).map(dataset => {
      return {
        datasetId: dataset.datasetID,
        role: dataset.rlsRole
      } as IRlsRole;
    });
  }
  return datasetIDs.filter(dataset => dataset.rlsRole).map(dataset => {
    return {
      datasetId: dataset.datasetID,
      role: dataset.rlsRole
    } as IRlsRole;
  });
};
export const findReportVisualByKey = (reportEntity: IReportVisual[], key: string, embedToken: string, embedUrl: string): EmbedReportVisualConfiguration | undefined => {
  const visual = reportEntity?.find((v: IReportVisual) => v.key === key);
  if (!visual) {
    return undefined;
  }
  return {
    id: visual.reportId,
    pageName: visual.pageName,
    visualName: visual.visualName,
    cssClassName: "report-visual-embed-container",
    embedToken: embedToken,
    embedUrl: embedUrl
  };
};
const localeCompare = (a: string, b: string) => a.localeCompare(b);
const fetchToken = async (instance: IPublicClientApplication, activeAccount: AccountInfo, authResult: AuthenticationResult, callBack: Function): Promise<void> => {
  let apiTokenResult: AuthenticationResult | undefined;
  const accessToken = authResult?.accessToken;
  if (accessToken) {
    const isExpired = isTokenExpired(accessToken);
    if (isExpired) {
      apiTokenResult = await getTokenAccess(instance, activeAccount, true);
      callBack(apiTokenResult);
    }
    return;
  }
  ;
  apiTokenResult = await getTokenAccess(instance, activeAccount, true);
  callBack(apiTokenResult);
};
const trimTwoUppercaseCharsAtBeginning = (text: string) => {
  if (text == undefined) {
    return "";
  }
  const firstLowerCaseIndex = text.search(/[a-z]/);
  return text.substring(firstLowerCaseIndex - 1);
};
export const setLoginType = (type: string) => {
  if (!localStorage) return;
  localStorage.setItem(LOGIN_TYPE_STORAGE_NAME, type);
};
export const getLoginType = () => {
  if (!localStorage) return "";
  return localStorage.getItem(LOGIN_TYPE_STORAGE_NAME);
};
export const isB2cUserLoggingIn = () => {
  //Check if user is logged in as B2C
  if (typeof window !== 'undefined') {
    return localStorage.getItem(LOGIN_TYPE_STORAGE_NAME) === B2C_LOGIN_TYPE;
  }
  return false;
};
const currencyFormat = (value: number | null) => '$ ' + (value ?? 0);
export const currencyFormatUsd = (params: any) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(params.value);
};
export const dateFormatter = (params: any) => {
  return params.value ? moment(params.value).format(DATE_FORMAT) : '';
};
const monthNameToDateTimeString = (monthName: string): string => {
  const monthMap: {
    [key: string]: number;
  } = {
    "January": 1,
    "February": 2,
    "March": 3,
    "April": 4,
    "May": 5,
    "June": 6,
    "July": 7,
    "August": 8,
    "September": 9,
    "October": 10,
    "November": 11,
    "December": 12
  };
  const monthNumber = monthMap[monthName];
  const date = new Date(1904, monthNumber, 1);
  const dateString = date.toISOString().slice(0, 10) + "T00:00:00";
  return dateString;
};
const dateTimeStringToMonthName = (dateTimeString: string | null): string | undefined => {
  if (dateTimeString == null) {
    return undefined;
  }
  const monthNumber = new Date(dateTimeString).getUTCMonth();
  const monthNames: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthName = monthNames[monthNumber];
  return monthName;
};
const showFormFields = (data: any, formFields: IPropertyType[]) => {
  return formFields.map(formField => {
    if (formField.isHidden) {
      return;
    }
    const nameField = formField.name;
    let formattedValue: any = undefined;
    if (data) {
      formattedValue = formField.formatValue ? formField.formatValue(data[nameField]) : data[nameField];
    }
    switch (formField.type) {
      case "string":
      case "number":
        return <TextFormControl key={nameField} name={formField.name} label={formField.text} textAlign={formField.textAlign} value={formattedValue} onChange={formField.onChange} isNumberInput={formField.type === "number"} decimalDigits={formField.decimalDigits} isRequired={formField.isRequired} isDisabled={formField.isDisabled} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formField.formState} isHidden={formField.isHidden} textSx={formField.textSx} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} onlyNumber={formField.onlyNumber} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      case "boolean":
        return <ToggleFormControl key={nameField} name={formField.name} value={formattedValue} onChange={formField.onChange} label={formField.text} isRequired={formField.isRequired} isDisabled={formField.isDisabled} formState={formField.formState} xsLabel={formField.xsLabel} textSx={formField.textSx} isHidden={formField.isHidden} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      case "list":
        return <DropdownFormControl key={nameField} name={formField.name} label={formField.text} defaultValue={formField.defaultValue} value={formattedValue} onChange={formField.onChange} isRequired={formField.isRequired} listValues={formField.listValues ?? []} isDisabled={formField.isDisabled} zIndex={formField.zIndex} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formField.formState} textSx={formField.textSx} isHidden={formField.isHidden} textAlign={formField.textAlign} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} showSelect={formField.showSelect} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      case "date":
        return <DatePickerFormControl key={nameField} name={formField.name} label={formField.text} value={formattedValue ?? null} onChange={formField.onChange} isRequired={formField.isRequired} isDisabled={formField.isDisabled} xsLabel={formField.xsLabel} formState={formField.formState} textSx={formField.textSx} isHidden={formField.isHidden} textAlign={formField.textAlign} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      case "multiple":
        return <MultilineTextFormControl key={nameField} name={formField.name} label={formField.text} value={formattedValue} onChange={formField.onChange} isRequired={formField.isRequired} isDisabled={formField.isDisabled} isHidden={formField.isHidden} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formField.formState} textSx={formField.textSx} textAlign={formField.textAlign} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      case "checkbox":
        return <CheckboxFormControl key={nameField} name={formField.name} value={formattedValue} onChange={formField.onChange} label={formField.text} textAlign={formField.textAlign} isRequired={formField.isRequired} isDisabled={formField.isDisabled} formState={formField.formState} xsLabel={formField.xsLabel} textSx={formField.textSx} isHidden={formField.isHidden} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      default:
        return;
    }
  });
};
const showNewFormFields = (data: any, formFields: IPropertyType[], formState?: FormState, isReadOnly?: boolean) => {
  return formFields?.map(formField => {
    if (formField.isHidden) {
      return <></>;
    }
    const nameField = formField.name;
    let formattedValue: any = undefined;
    if (data) {
      formattedValue = formField.formatValue ? formField.formatValue(data[nameField]) : data[nameField];
    }
    switch (formField.type) {
      case "string":
      case "number":
        return <NewTextFormControl key={nameField} name={formField.name} label={formField.text} textAlign={formField.textAlign} value={formattedValue} onChange={formField.onChange} isNumberInput={formField.type === "number"} decimalDigits={formField.decimalDigits} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formState} isHidden={formField.isHidden} textSx={formField.textSx} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} onlyNumber={formField.onlyNumber} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} isExpanded={formField.isExpanded} />;
      case "boolean":
        return <NewToggleFormControl key={nameField} name={formField.name} value={formattedValue} onChange={formField.onChange} label={formField.text} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} formState={formState} xsLabel={formField.xsLabel} textSx={formField.textSx} isHidden={formField.isHidden} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} itemSx={formField.itemSx} />;
      case "list":
        return <NewDropdownFormControl key={nameField} name={formField.name} label={formField.text} defaultValue={formField.defaultValue} value={formattedValue} onChange={formField.onChange} isRequired={formField.isRequired} listValues={formField.listValues ?? []} isDisabled={formField.isDisabled || isReadOnly} zIndex={formField.zIndex} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formState} textSx={formField.textSx} isHidden={formField.isHidden} textAlign={formField.textAlign} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} showSelect={formField.showSelect} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} errorName={formField.errorName} isExpanded={formField.isExpanded} isZeroDefaultSelect={formField.isZeroDefaultSelect} />;
      case "autoComplete":
        return <NewAutoCompleteFormControl key={nameField} name={formField.name} label={formField.text} defaultValue={formField.defaultValue} value={formattedValue} onChange={formField.onChange} isRequired={formField.isRequired} listValues={formField.listValues ?? []} isDisabled={formField.isDisabled || isReadOnly} zIndex={formField.zIndex} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formState} textSx={formField.textSx} isHidden={formField.isHidden} textAlign={formField.textAlign} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} showSelect={formField.showSelect} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} isFreeSolo={formField.isFreeSolo} />;
      case "date":
        return <NewDatePickerFormControl key={nameField} name={formField.name} label={formField.text} value={formattedValue ?? null} onChange={formField.onChange} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} xsLabel={formField.xsLabel} formState={formState} textSx={formField.textSx} isHidden={formField.isHidden} textAlign={formField.textAlign} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} itemSx={formField.itemSx} defaultValue={formField.defaultValue} />;
      case "multiple":
        return <NewMultilineTextFormControl key={nameField} name={formField.name} label={formField.text} value={formattedValue} onChange={formField.onChange} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} isHidden={formField.isHidden} xsLabel={formField.xsLabel} itemSx={formField.itemSx} formState={formState} textSx={formField.textSx} textAlign={formField.textAlign} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} placeHolder={formField.placeHolder} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} />;
      case "checkbox":
        return <CheckboxFormControl key={nameField} name={formField.name} value={formattedValue} onChange={formField.onChange} label={formField.text} textAlign={formField.textAlign} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} formState={formState} xsLabel={formField.xsLabel} textSx={formField.textSx} isHidden={formField.isHidden} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} itemSx={formField.itemSx} />;
      case "radio":
        return <NewRadioGroupFormControl key={nameField} name={formField.name} value={formattedValue} onChange={formField.onChange} label={formField.text} textAlign={formField.textAlign} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} formState={formState} xsLabel={formField.xsLabel} textSx={formField.textSx} isHidden={formField.isHidden} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} isHideLabel={formField.isHideLabel} listValues={formField.listValues} itemSx={formField.itemSx} isVertical={formField.isVertical} />;
      case "multipleSelect":
        return <NewMultipleSelectFormControl key={nameField} name={formField.name} value={formattedValue} onChange={formField.onChange} label={formField.text} textAlign={formField.textAlign} isRequired={formField.isRequired} isDisabled={formField.isDisabled || isReadOnly} formState={formState} xsLabel={formField.xsLabel} textSx={formField.textSx} isHidden={formField.isHidden} defaultValue={formField.defaultValue} textErrorAlign={formField.textErrorAlign} xsInput={formField.xsInput} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} listValues={formField.listValues ?? []} itemSx={formField.itemSx} />;
      case "empty":
        return <EmptyFormControl key={nameField} name={nameField} label={formField.text} value={formattedValue} isRequired={formField.isRequired} onChange={formField.onChange} xsFormControl={formField.xsFormControl} mdFormControl={formField.mdFormControl} itemSx={formField.itemSx} />;
      default:
        return <></>;
    }
  }) ?? <></>;
};
const isValidStateProfile = (stateProfile: IStateProfileDetail | null) => {
  const validStateProfile = StateProfileSchema.safeParse(stateProfile);
  return validStateProfile.success;
};
const redirectPathPage = (router: AppRouterInstance, url: string) => {
  const relativePath = url.replace(window.location.origin, '');
  return router.push(relativePath);
};
const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = '';
};
export { localeCompare, checkRole, formatDateToLocal, generatePagination, checkRoleAdmin, fetchToken, trimTwoUppercaseCharsAtBeginning, currencyFormat, monthNameToDateTimeString, dateTimeStringToMonthName, showFormFields, isValidStateProfile, redirectPathPage, beforeUnloadHandler, showNewFormFields };