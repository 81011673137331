import { SxProps, Theme } from "@mui/material";
import { currencyFormat } from "@utils/helpers";
import React from "react";

type TableStructure = {
    keyColumn: string;
    columns: {
        colName: string;
        colSpan: number;
        propertyName: string;
        hasFilter?: boolean;
        mappingData?: string[];
        colFormat?: typeof currencyFormat,
        cellSx?: any,
        sx?: SxProps<Theme>
    }[];
};

type ListItem = {
    name: string;
    value: string | number | boolean;
    icon?: JSX.Element | string;
};

enum ModalAction {
    OPEN = 0,
    CLOSE = 1,
    CLOSE_SUCCESS = 2,
    CLOSE_SUCCESS_2 = 3,
}

enum ActionStatus {
    SUCCESS = "SUCCESS",
    FAIL = "FAIL",
    UNSET = "UNSET",
}

export enum UserStatus {
    Active = "Active",
    Pending = "Pending",
    Inactive = "Inactive",
}

type FormState = {
    key: ActionStatus;
    message: string;
    errors: Record<string, string[] | undefined>;
    timestamp?: number;
};

const ROLE_ADMIN = "admin";
const SELECT_ALL_VALUE = "all-value";

const EMPTY_FORM_STATE: FormState = {
    key: ActionStatus.UNSET,
    message: "",
    errors: {},
    timestamp: Date.now(),
};

type FieldErrorProps = {
    formState: FormState;
    name: string;
    textAlign?: TextAlign;
};

interface IPageCriteria {
    keyWord?: string;
    orderBy?: string;
    desc?: boolean;
    page?: number;
    pageSize?: number;
}

type FormFieldType = "string" | "number" | "list" | "date" | "boolean" | "multiple" | "checkbox" | "radio" | "empty" | "autoComplete" | "multipleSelect";

type TextAlign = "left" | "right" | "center";

interface IPropertyType {
    type: FormFieldType;
    name: string;
    text: string | React.ReactNode;
    defaultValue?: any;
    showSelect?: boolean;
    placeHolder?: string;
    onlyNumber?: boolean;
    isDisabled?: boolean;
    isRequired: boolean;
    isHidden?: boolean;
    onChange: Function;
    formState?: FormState;
    decimalDigits?: number;
    listValues?: any[];
    zIndex?: number;
    xsLabel?: number;
    xsInput?: number;
    xsFormControl?: number;
    mdFormControl?: number;
    textAlign?: TextAlign;
    textErrorAlign?: TextAlign;
    itemSx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    isHideLabel?: boolean;
    isVertical?: boolean;
    isFreeSolo?: boolean;
    errorName?: string;
    itemFocus?: boolean;
    isExpanded?: boolean;
    isZeroDefaultSelect?: boolean;
    formatValue?: (value: string) => any;
}

interface IFormControlProperty {
    label: string | React.ReactNode;
    name: string;
    defaultValue?: any;
    value: any;
    placeHolder?: string;
    onlyNumber?: boolean;
    isRequired: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    onChange: Function;
    formState?: FormState;
    isNumberInput?: boolean;
    decimalDigits?: number;
    xsLabel?: number;
    xsInput?: number;
    xsFormControl?: number;
    mdFormControl?: number;
    errorName?: string;
    textAlign?: TextAlign;
    textErrorAlign?: TextAlign;
    itemSx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    isExpanded?: boolean;
    zIndex?: number;
}

interface IMenuItem {
    id: string;
    text: string;
    pageName?: string;
    pageDescription?: string;
    name?: string;
    href?: string;
    icon?: JSX.Element | string;
    children?: IMenuItem[];
    guardActive?: boolean;
    powerBIReportId?: number;
}

interface IDropdownFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    placeHolder?: string;
    listValues: ListItem[] | any[];
    showSelect?: boolean;
    isExpanded?: boolean;
    isZeroDefaultSelect?: boolean;
}


interface IAutoCompleteFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    placeHolder?: string;
    listValues: ListItem[] | any[];
    showSelect?: boolean;
    isFreeSolo?: boolean;
}

interface ICheckboxFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    isShowCheckboxLabel?: boolean;
}

interface IRadioFormControlProperty extends IFormControlProperty {
    listValues?: any[];
    isHideLabel?: boolean;
    isVertical?: boolean;
    labelToolTip?: React.ReactNode
}

interface IMultipleSelectFormControlProperty extends IFormControlProperty {
    zIndex?: number;
    placeHolder?: string;
    listValues: any[];
}

const defaultEmptyProperties: IPropertyType = {
    type: "empty",
    name: "empty name",
    text: "empty",
    isDisabled: false,
    isRequired: false,
    onChange: () => { },
    xsFormControl: 12,
}

const defaultStringProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "string",
}

const defaultNumberProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "number",
    decimalDigits: 0,
}

const defaultDropDownProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "list",
    listValues: [],
}


const defaultMultipleSelectProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "multipleSelect",
    listValues: [],
}

const defaultAutoCompleteProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "autoComplete",
    listValues: [],
}

const defaultDateProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "date",
}

const defaultMultiLineProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "multiple",
}

const defaultToggleProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "boolean",
}

const defaultCheckboxProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "checkbox",
}

const defaultRadioProperties: IPropertyType = {
    ...defaultEmptyProperties,
    type: "radio",
}


interface IBreadCrumbLink {
    text: string;
    href?: string;
}

export {
    ActionStatus,
    EMPTY_FORM_STATE,
    ROLE_ADMIN,
    SELECT_ALL_VALUE,
    ModalAction,
    defaultStringProperties,
    defaultAutoCompleteProperties,
    defaultNumberProperties,
    defaultDropDownProperties,
    defaultDateProperties,
    defaultMultiLineProperties,
    defaultToggleProperties,
    defaultCheckboxProperties,
    defaultEmptyProperties,
    defaultRadioProperties,
    defaultMultipleSelectProperties,
}
export type { TableStructure, ListItem, FormState, FieldErrorProps, FormFieldType, TextAlign, }
export type {
    IPageCriteria,
    IPropertyType,
    IFormControlProperty,
    IDropdownFormControlProperty,
    IAutoCompleteFormControlProperty,
    IMenuItem,
    ICheckboxFormControlProperty,
    IRadioFormControlProperty,
    IBreadCrumbLink,
    IMultipleSelectFormControlProperty
}

