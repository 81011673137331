import { IDataLayoutItem } from "./dataLayoutDefinitions";
import { defaultAutoCompleteProperties, defaultDropDownProperties, defaultStringProperties, FormState, IPropertyType } from "./definitions";

interface IDataStreamItem {
    id: number;
    dataLayoutId: number | null;
    sourceSystemId: number | null;
    clientCode: string | null;
    clientName: string | null;
    createdBy: string | null;
    createdDate: string | null;
    processorCode: string | null;
    programCode: string | null;
    processorName: string | null;
    dataStreamName: string;
    dataLayoutName: string;
    brandCode: string;
    silverTransactionDeduplication: boolean | null;
    fileNameMappings: string;
    fileNamePattern: string;
    filePath: string;
    dataStreamType: DATA_STREAM_TYPES,
    isCompressed: boolean | null;
    isPasswordProtected: boolean | null;

    dataLayout: IDataLayoutItem | null;
    sourceSystem: ISourceSystemItem | null;
}

export interface ISourceSystemItem {
    id: number;
    description: string;
    ingestionType: string;
    rootPath: string;
    connectionSecret: string;
    connectionKey: string;
    active: boolean | null;
    createdBy: string | null;
    createdDate: string | null;
}


export const dataStreamResponsiveProps = {
    xsFormControl: 6,
    mdFormControl: 6,
}

export const dataStreamStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...dataStreamResponsiveProps,
}

export const dataStreamDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...dataStreamResponsiveProps,
}

export const dataStreamAutoCompleteProperties: IPropertyType = {
    ...defaultAutoCompleteProperties,
    ...dataStreamResponsiveProps,
}

export const FILE_FORMATS = {
    DELIMITED: 1,
    DSR: 2,
    EMAF: 3,
    ENCRYPTEDDELIMITED: 4,
    EXCEL: 5,
    FIXED_WIDTH: 6,
    XML: 7,
};

export enum DATA_STREAM_TYPES {
    Published,
    Draft,
};

type NewDraftDataStreamFormState = FormState & {
    id?: number;
};

interface INewDraftDataStreamItem {
    dataStreamName: string;
}


export type { IDataStreamItem, NewDraftDataStreamFormState, INewDraftDataStreamItem, }